<script setup>
import { useViewEnforcements } from "./buildings_business_logic";
import ViewEnforcementsTable from "@/components/buildings/tables/ViewEnforcementsTable.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref, onMounted } from "vue";
import { resetSnackbarOnMount, snackbarData } from "@/components/utils/snackbar/logic";
import { useRouter, useRouterFromInstance } from "@/utils/vue_helpers";
import Pagination from "../utils/Pagination.vue";
import LoadingArea from "../utils/LoadingArea.vue";
import { routes } from "@/router/routes";
import Instructions from "./Instructions.vue";
//import Table from "@/components/utils/Table.vue";

import store from "@/store/index";
import { GET_USER_DATA } from "@/store/getters/user";
import ViewEnforcement from "./subModules/ViewEnforcement.vue";


resetSnackbarOnMount();

const { router } = useRouterFromInstance();

const { enforcementsData, searchModel } = useViewEnforcements(store.getters[GET_USER_DATA].profile.role_name);

const pages = {
  VIEW_ENFORCEMENT_DETAILS: 0,
  VIEW_ENFORCEMENT_APPLICATION: 1

};

const currentPage = ref(pages.VIEW_ENFORCEMENT_DETAILS); //ref(pages.VIEW_ENFORCEMENT_DETAILS)

const enforcementUnderView = ref(null);

function moveToViewHouseTable() {
  currentPage.value = pages.VIEW_ENFORCEMENT_DETAILS;

}

function viewEnforcement(item){
  enforcementUnderView.value = item
  currentPage.value = pages.VIEW_ENFORCEMENT_APPLICATION;
  
}

function moveToViewAllEnforcements(){
  currentPage.value = pages.VIEW_ENFORCEMENT_DETAILS;

}

async function onPaginationClicked(newPage) {
  enforcementsData.paginationData.currentPage = newPage;

}

onMounted(() => {
  console.log('Inside OnMounted fetchDataRequestStatus')
  console.log(enforcementsData.fetchDataRequestStatus)

  console.log('Inside OnMounted Moseti')
  console.log(enforcementsData.fetchedData)

  console.log('Inside OnMounted 123456')
  console.log(enforcementsData)

  console.log('CurrentPage Value')
  console.log(currentPage.value)

  console.log('profile role_name')
  console.log(store.getters[GET_USER_DATA].profile.role_name)
 

})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage" touchless continuous>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              Enforcements
            </div>
          </v-col>
        </v-row>
        <v-row justify-md="center" dense>
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field class="background text-field" outlined dense v-model="searchModel" clearable />
            </LabelledField>
          </v-col>
        </v-row>
        <LoadingArea :loading="enforcementsData.fetchingData" class="mt-4">

          <v-row>
            <v-col cols="12">
              <ViewEnforcementsTable :buildings="enforcementsData.fetchedData" :loading="enforcementsData.fetchingData" @viewEnf="viewEnforcement" @goBackClicked="moveToViewAllEnforcements"/>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <Pagination :current-page="enforcementsData.paginationData.currentPage"
                :total-number-of-items="enforcementsData.paginationData.total"
                :items-per-page="enforcementsData.paginationData.perPage" @paginationClicked="enforcementsData.setNewPage($event)">
              </Pagination>
            </v-col>
          </v-row>
        </LoadingArea>
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_ENFORCEMENT_APPLICATION">
        <v-row>
          <v-col cols="12">
            <ViewEnforcement :enforcement_application="enforcementUnderView" @goBackClicked="moveToViewHouseTable" />

          </v-col>
        </v-row>
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>
