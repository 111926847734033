<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import BackButton from "@/components/utils/BackButton.vue";
import ViewItemSectionTitle from "../../utils/ViewItemSectionTitle.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import Table from "../../utils/Table.vue";
import ActionButton from "../../utils/ActionButton.vue";
import { useDownloadFile } from "@/utils/vue_helpers";
import { actions } from "@/utils/constants";
import { getFormattedBillDate } from "@/utils/functions";
import { getWorkflowApprovers, snackBarErrorMessage, snackBarSuccessMessage, snackBarInfoFunction } from "../buildings_business_logic";
import { fieldRequired, } from "@/utils/input_validation.js";

const { downloadFile } = useDownloadFile();

const { snackbar_info } = snackBarInfoFunction()

const props = defineProps({
    enforcement_application: {
        type: Object,
        default: null,
    }
});



const enforcementDetails = computed(() => {
    let enforcementDetails = {};

    enforcementDetails = getBuildingDetails(props.enforcement_application);
    return enforcementDetails;
});

const workflow_approvers_array = ref([])

const enforcement_data = reactive({
  enforcement_reason: null,
  enforcement_result: null,
  target_enforcement_date: null,
  enforcer: null

})

function getBuildingDetails(enforcement_application) {
    const enforcementDetails = {
        section1: {},
        section2: {},
    };
    enforcementDetails.section1["Parcel Number"] = enforcement_application.building_application.building.parcel_no;
    enforcementDetails.section1["Parcel Number"] = enforcement_application.building_application.building.parcel_no;
    enforcementDetails.section1["Subcounty"] = enforcement_application.building_application.building.sub_counties.sub_county_name;
    enforcementDetails.section1["Ward"] = enforcement_application.building_application.building.ward.ward_name;
    enforcementDetails.section1["Town"] = enforcement_application.building_application.building.towns.town_name;
    enforcementDetails.section1["Latitude"] = enforcement_application.building_application.building.latitude;
    enforcementDetails.section1["Longitude"] = enforcement_application.building_application.building.longitude;

    enforcementDetails.section2["Cost"] = enforcement_application.building_application.building.cost;
    enforcementDetails.section2["Unit of Measure"] = 'Sq Meters'
    enforcementDetails.section2["Plinth Area"] = enforcement_application.building_application.building.plinth_area
    //   enforcementDetails.section2["Development Type"] = enforcement_application.building_application.building.building_development_type.type_of_development

    //building_application.building.building_development_type.type_of_development


    enforcementDetails.section2["Number of Floors"] = enforcement_application.building_application.building.number_of_floors
    enforcementDetails.section2["Approval Number"] = enforcement_application.building_application.building.approval_number
    enforcementDetails.section2["Physical Location/Lanmark"] = enforcement_application.building_application.building.physical_address

    return enforcementDetails;
}

function checkIfBillReceiptPaid(item) {
  if (item == 'Bill') {

    if (bills_and_receipts_object.value?.bill_workflow?.status_name == 'Paid') {
      return true

    }
    return false


  }
  else if (item == 'Receipt') {

    if (bills_and_receipts_object.value?.bills_invoices?.[0].invoice_receipt?.receipt?.amount) {
      return true

    }
    return false

  }

}


function returnBillReceiptString(item) {
  if (item == 'Bill') {

    if (bills_and_receipts_object.value?.bill_workflow?.status_name == 'Paid') {
      return 'Paid'

    }
    return 'Unpaid'


  }
  else if (item == 'Receipt') {

    if (bills_and_receipts_object.value?.bills_invoices?.[0].invoice_receipt?.receipt?.amount) {
      return 'Paid'

    }
    return 'Unpaid'

  }

}

function onDownloadBillClicked(bill_object) {
  downloadFile({ billId: props.enforcement_application.building_enforcement_bill.bills_id, billNumber: props.enforcement_application.building_enforcement_bill.bills.bill_number });

}

const bills_and_receipts_array = computed(() => {
  const bills_and_receipts_array = [{ 'id': '1', 'type': 'Bill', 'number': '', 'date': '', 'amount': '', 'status': '' }, { 'id': '2', 'type': 'Receipt', 'number': '', 'date': '', 'amount': '', 'status': '' }]
  return bills_and_receipts_array

});

const bills_and_receipts_object = computed(() => {
  const bills_and_receipts_object = props.enforcement_application.building_enforcement_bill?.bills
  return bills_and_receipts_object

});

function onDownloadReceiptClicked(recept_object) {
  if (props.enforcement_application.building_enforcement_bill.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number) {
    const recept_num = props.enforcement_application.building_enforcement_bill.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number
    downloadFile({ recept_num });

  }
  else {
    snackBarErrorMessage("No Receipt Available, Please Pay for this Bill.")

  }


}

onMounted(async () => {
    console.log("props.enforcement_application")
    console.log(props.enforcement_application)

    enforcement_data.enforcement_reason = props.enforcement_application.description
    enforcement_data.enforcement_result = props.enforcement_application.results_of_enforcement
    enforcement_data.target_enforcement_date = props.enforcement_application.target_enforcement_date
    enforcement_data.enforcer = props.enforcement_application.enforcer.name

    console.log("enforcement_data.target_enforcement_date")
    console.log(enforcement_data.target_enforcement_date)

    const workflow_approvers = await getWorkflowApprovers("Building Enforcements", props.enforcement_application.id)

    console.log("workflow_approvers")
    console.log(workflow_approvers)

    if (workflow_approvers.length != undefined) {
      workflow_approvers_array.value = workflow_approvers

    }

})

</script>

<template>
    <v-container fluid class="pa-0">
      <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-row>
            <v-col cols="12" md="1">
                <BackButton @click="$emit('goBackClicked')">
                    Back To Houses
                </BackButton>
            </v-col>
            <v-col cols="12" md="10">
                <div class="text-h6 text-center font-weight-bold primary--text">
                    Enforcement Details
                </div>
            </v-col>
            <v-spacer />
        </v-row>

        <v-row justify-md="center">
            <v-col v-if="$mobileBreakpoint" cols="12">
                <Table :headers="smallScreenHouseDetails.tableHeaders"
                    :items="smallScreenHouseDetails.houseDetailsEnclosedInArray">
                </Table>
            </v-col>

            <template v-else>
                <v-col v-for="(houseDetailSection, sectionName, index) in enforcementDetails" :key="sectionName"
                    cols="12" md="6">

                    <v-row v-if="index == 0">
                        <v-col cols="auto">
                            <ViewItemSectionTitle>Building Plan Details</ViewItemSectionTitle>
                        </v-col>
                    </v-row>

                    <v-simple-table dense class="building_application-details-table">
                        <tbody>
                            <tr v-for="(houseAttribute, attributeTitle) in houseDetailSection" :key="attributeTitle">
                                <td class="font-weight-bold">
                                    {{ attributeTitle }}
                                </td>
                                <td>
                                    <template v-if="attributeTitle !== 'Occupied'">
                                        {{ houseAttribute }}</template>
                                    <template v-else>
                                        <v-icon v-if="houseAttribute === true" color="success">mdi-check-circle</v-icon>
                                        <v-icon v-else color="error">mdi-close</v-icon>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </template>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <ViewItemSectionTitle>Enforce Building</ViewItemSectionTitle>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <LabelledField label="Reason for Enforcement" required>
                    <v-textarea class="text-field-background" v-model="enforcement_data.enforcement_reason" outlined dense required hide-details="auto"></v-textarea>
                </LabelledField>

            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <LabelledField label="Result for Enforcement" required>
                    <v-textarea class="text-field-background" v-model="enforcement_data.enforcement_result" outlined dense required hide-details="auto"></v-textarea>
                </LabelledField>

            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <LabelledField label="License Expiry Date" required>
                    <DatePicker
                    v-model="enforcement_data.target_enforcement_date"
                    :rules="[fieldRequired]"
                    :textFieldProps="{
                        outlined: true,
                        dense: true,
                        class: 'text-field-background',
                        'hide-details': 'auto',
                    }" only-show-value-on-change />
                </LabelledField>
            </v-col>

            <v-col cols="12" md="5">
                <LabelledField label="Selected Enforcer" required>
                    <v-text-field class="text-field-background" v-model="enforcement_data.enforcer" outlined dense required
                        hide-details="auto"></v-text-field>
                </LabelledField>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="auto">
                <ViewItemSectionTitle>Bill and Receipt Details</ViewItemSectionTitle>
            </v-col>
        </v-row>

        <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'No.',
            value: 'id',

          },
          {
            text: 'Type',
            value: 'type',

          },
          {
            text: 'Number',
            value: 'number',
            fn: (bills_and_receipts_array) => {
              if (bills_and_receipts_array.type == 'Bill') {
                return bills_and_receipts_object?.bill_number

              } else if (bills_and_receipts_array.type == 'Receipt') {
                return bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number

              }

              return ''

            }

          },
          {
            text: 'date',
            value: 'Date',
            fn: (bills_and_receipts_array) => {
              if (bills_and_receipts_array.type == 'Bill') {
                return getFormattedBillDate(bills_and_receipts_object?.created_at)

              } else if (bills_and_receipts_array.type == 'Receipt') {
                if (bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.payment_date) {
                  return getFormattedBillDate(bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.payment_date)
                }

              }

              return ''

            }

          },
          {
            text: 'amount',
            value: 'Amount',
            fn: (bills_and_receipts_array) => {
              if (bills_and_receipts_array.type == 'Bill') {
                return bills_and_receipts_object?.amount

              } else if (bills_and_receipts_array.type == 'Receipt') {
                return bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.amount

              }

              return ''

            }

          },
          {
            text: 'status',
            action: true,
            value: 'status',

          },

          {
            text: 'Download',
            value: 'download',
            action: true

          },


        ]" dense :items="bills_and_receipts_array" bodyClasses="text-caption">

          <template #item.status="{ item: bills_and_receipts_array_item }">
            <span :class="{
              'font-weight-bold': true,
              'green--text': checkIfBillReceiptPaid(bills_and_receipts_array_item.type),
              'red--text': !checkIfBillReceiptPaid(bills_and_receipts_array_item.type),
            }">{{ returnBillReceiptString(bills_and_receipts_array_item.type) }}</span>
          </template>

          <template #item.download="{ item: bills_and_receipts_array_item }">
            <ActionButton v-if="bills_and_receipts_array_item.type == 'Bill'" :action="actions.downloadBill" inTable
              @click="onDownloadBillClicked(bills_and_receipts_array_item)" />
            <ActionButton v-else :action="actions.downloadReceipt" inTable
              @click="onDownloadReceiptClicked(bills_and_receipts_array_item)" />
          </template>

        </Table>

      </v-col>
    </v-row>

    <v-row>
        <v-col cols="auto">
            <ViewItemSectionTitle>Workflow</ViewItemSectionTitle>
        </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'User',
            value: 'user',
          },
          {
            text: 'Role',
            value: 'role',
          },
          {
            text: 'Action',
            value: 'action',
          },
          {
            text: 'Comment',
            value: 'comment',
          },
          {
            text: 'Approval Date',
            value: 'actioned',
          },

        ]" dense :items="workflow_approvers_array" bodyClasses="text-caption">

        </Table>

      </v-col>
    </v-row>


    </v-container>

</template>